<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <OrderSearch :showOrderStatus="'delivery_order'" @getSearchData="getSearchData"></OrderSearch>
      <div>
<!--        <a-button v-if="isVoid && selectedRows.length > 0" type="primary" @click="toHandler('void')">作废</a-button>-->
<!--        <a-button v-if="isCancel && selectedRows.length > 0" type="primary" @click="toHandler('cancel')"-->
<!--          >取消订单</a-button-->
<!--        >-->
        <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
        <downLoad v-if="isExport" method="post" api="/api/order/order/orderInfo/excelExportDealerOrderList" :params="searchData" name="经销商采购单(已发货).xls">批量导出订单</downLoad>
      </div>
      <!-- 数据表格 -->
      <OrderList
        :url="'/api/order/order/orderInfo/dealerOrderList'"
        :columnsData="columns"
        :status="[5, 6]"
        @orderData="getSelectedRows($event)"
      ></OrderList>
      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal"></OrderInfo-edit-modal>
      <EditAddressModal ref="EditAddressModal"></EditAddressModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <a-modal
        title="操作"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="原因">
            <a-textarea v-model="rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">作废</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit'" type="danger" @click="onModifyOrderStatus('refuse')">拒绝</a-button>
          <a-button v-if="handle == 'audit'" type="primary" @click="onModifyOrderStatus('audit')">通过</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModal.vue'
import { delOrderInfo, listOrderInfo, selectByIdOrderInfo } from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'orderInfo',
  components: {
    OrderInfoEditModal,
    EditAddressModal,
    InvoiceEditModal,
    OrderInfoCheckModal,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'bigAreaName',
        'dealerName',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'createTime',
        'address',
        'consigneeType',
        'supplierName',
        'fhNum',
        'deliveryProperties',
        'expectTime',
        'purchaseAndSalesContractType',
        'flagFreeShipping',
        'policyTotalAmount',
        'rebateTotalAmount',
      ],
      tableData: [],
      searchData: {statusList: [5, 6]}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isAdd: checkPermission('order:info:add'),
      isEdit: checkPermission('order:info:edit'),
      isDelete: checkPermission('order:info:delete'),
      isEditAddress: checkPermission('order:info:edit_address'),
      isEditInvoice: checkPermission('order:info:edit_invoice'),
      isCheck: checkPermission('order:info:check'),
      isAudit: checkPermission('order:info:audit'),
      isCancel: checkPermission('order:info:cancel'),
      isVoid: checkPermission('order:info:void'),
      isExport: checkPermission('order:info:export'),
      visible: false,
      confirmLoading: false,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 导出条件
    getSearchData(row){
      this.searchData = row
      this.searchData.statusList = [5,6]
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    getSelectedRows(rows) {
      this.selectedRows = rows
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.$bus.$emit('getList')
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0])
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      let status = 0
      if (name == 'audit') {
        status = 4
      } else if (name == 'refuse') {
        status = 3
      } else if (name == 'void') {
        status = 1
      } else if (name == 'cancel') {
        status = 2
      }
      let obj = {
        orderId: this.selectedRows[0].id,
        orderStatus: status,
        verifyRemark: this.rejectReason,
      }
      this.axios.post(`/api/order/order/orderInfo/verifyOrderInfo`, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.$bus.$emit('getList')
          this.rejectReason = ''
          this.selectedRowKeys = []
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-bts {
  text-align: right;
}
</style>
